import { render } from "./patients.vue?vue&type=template&id=f23c5734&scoped=true"
import script from "./patients.vue?vue&type=script&lang=js"
export * from "./patients.vue?vue&type=script&lang=js"

import "./patients.vue?vue&type=style&index=0&id=f23c5734&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-f23c5734"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f23c5734"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f23c5734', script)) {
    api.reload('f23c5734', script)
  }
  
  module.hot.accept("./patients.vue?vue&type=template&id=f23c5734&scoped=true", () => {
    api.rerender('f23c5734', render)
  })

}

script.__file = "src/views/my/patients/patients.vue"

export default script